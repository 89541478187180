import {
  AttachMoney,
  CalendarToday,
  CarCrash,
  Delete,
  DirectionsCar,
  LocalGasStation,
  Phone,
  PhotoAlbumOutlined,
  Visibility,
  VisibilityOff,
  PersonAdd,
} from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
import { calculateProfit, formatDateTime, formatDistance, sanitizeNumber, formatPrice } from 'core/utils/inventoryUtils'
import React, { useCallback, useState } from 'react'
import icon from 'assets/Icon-OpticTruckWorks-02.png'
import boxTruck from 'assets/box truck.png'
import cutAway from 'assets/cutaway.png'
import cargoVan from 'assets/Cargo Van.png'
import stepVan from 'assets/step van.png'
import { generateOpticStockNumber } from 'core/api/inventory/generateStockNumber'
import VehicleForm from '../Forms/VehicleForm'
import { useInventory } from 'hooks/useInventory'

interface VehicleCardProps {
  collectionName: string
  vehicle: Vehicle
  onDeleteClick: () => void
  onManagePhotosClick: () => void
  onShowHideOnWebsiteClick: () => void
  onAssignUserClick: () => void
  assignedUserName?: string
  isAdmin?: boolean
  vehicles: Vehicle[]
}

const VehicleCard: React.FC<VehicleCardProps> = ({
  collectionName,
  vehicle,
  onDeleteClick,
  onManagePhotosClick,
  onShowHideOnWebsiteClick,
  onAssignUserClick,
  assignedUserName,
  isAdmin,
  vehicles,
}) => {
  const { userInfo } = useAuth()
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)
  const [optimisticShowOnWebsite, setOptimisticShowOnWebsite] = React.useState(vehicle.show_on_website)
  const [selectedVehicle, setSelectedVehicle] = React.useState<Vehicle | null>(null)
  const [openVehicleForm, setOpenVehicleForm] = useState(false)

  const { updateDocument, isLoading } = useInventory(collectionName)

  React.useEffect(() => {
    setOptimisticShowOnWebsite(vehicle.show_on_website)
  }, [vehicle.show_on_website])

  const { data: photos, isLoading: isLoadingPhoto } = usePhotosForInventoryObject(collectionName, vehicle.id)

  const handleShowHideClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setShowConfirmDialog(true)
  }

  const handleConfirmShowHide = () => {
    setOptimisticShowOnWebsite(!optimisticShowOnWebsite)
    onShowHideOnWebsiteClick()
    setShowConfirmDialog(false)
  }
  const icon = vehicle.type === 'Box Truck' ? boxTruck : vehicle.type === 'Cutaway' ? cutAway : vehicle.type === 'Cargo Van' ? cargoVan : stepVan

  const handleClickVehicle = useCallback((vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenVehicleForm(true)
  }, []) // Empty

  const handleEditVehicle = async (data: { [key: string]: string | number | boolean }) => {
    if (!selectedVehicle) return
    const sanitizedData = { ...data }
    sanitizedData.miles = sanitizeNumber(sanitizedData.miles)
    sanitizedData.optic_list_price = sanitizeNumber(sanitizedData.optic_list_price)
    sanitizedData.seller_asking_price = sanitizeNumber(sanitizedData.seller_asking_price)

    const newValues = Object.fromEntries(Object.entries(sanitizedData).filter(([, value]) => value !== undefined))
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      newValues.profit = calculateProfit(String(newValues.seller_asking_price), String(newValues.optic_list_price))
    }

    try {
      updateDocument({
        id: selectedVehicle.id,
        data: newValues,
      })
      setOpenVehicleForm(false)
    } catch (error) {
      setOpenVehicleForm(false)
      console.error('Error updating vehicle:', error)
    }
  }

  return (
    <>
      <Card
        sx={{
          minWidth: 275,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          '&:hover': { boxShadow: 6 },
          color: 'text.primary',
        }}
        onClick={() => handleClickVehicle(vehicle)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: '2px solid',
            borderColor: 'primary.light',
            opacity: 0,
            transition: 'opacity 0.3s',
            '&:hover': { opacity: 1 },
          }}
        />
        <CardHeader
          sx={{ flexShrink: 0 }}
          title={
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6'>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Typography>
              {vehicle.status && (
                <Badge
                  color={vehicle.status === 'Available' ? 'success' : 'secondary'}
                  badgeContent={vehicle.status}
                  sx={{
                    '& .MuiBadge-badge': {
                      marginRight: 4,
                      color: 'white',
                      bgcolor: vehicle.status === 'Available' ? '#2e7d32' : '',
                    },
                  }}
                />
              )}
            </Box>
          }
          subheader={
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='body2'>Stock Number: {vehicle.stock_number}</Typography>
            </Box>
          }
        />
        <Box sx={{ height: 200, flexShrink: 0 }}>
          {isLoadingPhoto ? (
            <Box display='flex' justifyContent='center' alignItems='center' height="100%">
              <CircularProgress sx={{ mt: 2 }} />
            </Box>
          ) : photos && photos.length > 0 ? (
            <CardMedia
              component='img'
              srcSet={`${photos[0].url}?w=82&h=82&fit=crop&auto=format&dpr=2 2x`}
              src={`${photos[0].url}?w=82&h=82&fit=crop&auto=format`}
              alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
              loading='lazy'
              sx={{
                height: '100%',
                objectFit: 'cover'
              }}
            />
          ) : (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height="100%"
              sx={{ bgcolor: 'background.default' }}
            >
              <img
                src={icon}
                alt='No Photo Available'
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                  objectFit: 'contain'
                }}
              />
            </Box>
          )}
        </Box>
        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                <DirectionsCar fontSize='small' /> Vehicle Info
              </Typography>
              <Typography variant='body2'>
                <strong>VIN:</strong> {vehicle.vin}
              </Typography>
              <Typography variant='body2'>
                <strong>Stock #:</strong> {vehicle.stock_number}
              </Typography>
              <Typography variant='body2'>
                <strong>Type:</strong> {vehicle.type}
              </Typography>
              <Typography variant='body2'>
                <strong>Size:</strong> {vehicle.size}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                <LocalGasStation fontSize='small' /> Performance
              </Typography>
              <Typography variant='body2'>
                <strong>Fuel:</strong> {vehicle.fuel}
              </Typography>
              <Typography variant='body2'>
                <strong>Miles:</strong> {formatDistance(vehicle.miles)}
              </Typography>
            </Grid>
            {isAdmin && (
              <Grid item xs={6}>
                <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                  <Phone fontSize='small' /> Contact
                </Typography>
                <Typography variant='body2'>
                  <strong>Contact:</strong> {vehicle.who}
                </Typography>
                <Typography variant='body2'>
                  <strong>Location:</strong> {vehicle.location}
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                <AttachMoney fontSize='small' /> Pricing
              </Typography>
              {isAdmin && (
                <Typography variant='body2'>
                  <strong>Buy Price:</strong> {formatPrice(vehicle.seller_asking_price)}
                </Typography>
              )}
              <Typography variant='body2'>
                <strong>Sell Price:</strong> {formatPrice(vehicle.optic_list_price)}
              </Typography>
              {isAdmin && (
                <Typography variant='body2'>
                  <strong>Potential Profit:</strong> {formatPrice(vehicle.profit)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box display='flex' alignItems='center' gap={2}>
                <Typography variant='body2' display='flex' alignItems='center' gap={1}>
                  <CalendarToday fontSize='small' /> <strong>Added:</strong> {formatDateTime(vehicle.date_added)}
                </Typography>
                {vehicle.sold_date && (
                  <Typography variant='body2' display='flex' alignItems='center' gap={1}>
                    <CalendarToday fontSize='small' /> <strong>Sold:</strong> {vehicle.sold_date}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ flexShrink: 0, borderTop: 1, borderColor: 'divider' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Box display='flex' alignItems='center'>
              <IconButton
                aria-label='Manage Photos'
                onClick={event => {
                  event.stopPropagation()
                  onManagePhotosClick()
                }}
                disabled={!userInfo?.email_verified}
              >
                <PhotoAlbumOutlined />
              </IconButton>
              <Switch
                checked={optimisticShowOnWebsite}
                icon={<VisibilityOff />}
                checkedIcon={<Visibility />}
                onClick={handleShowHideClick}
                color={optimisticShowOnWebsite ? 'success' : 'error'}
              />
            </Box>
            <IconButton
              aria-label='Delete Vehicle'
              onClick={event => {
                event.stopPropagation()
                console.log('click delete')
                onDeleteClick()
              }}
            >
              <Delete />
            </IconButton>
            <IconButton
              aria-label='Assign User'
              onClick={event => {
                event.stopPropagation()
                onAssignUserClick()
              }}
              disabled={!userInfo?.email_verified}
              sx={{ position: 'relative' }}
            >
              <PersonAdd />
              {assignedUserName && (
                <Typography
                  variant="caption"
                  sx={{
                    position: 'absolute',
                    bottom: -20,
                    fontSize: '0.6rem',
                    whiteSpace: 'nowrap',
                    maxWidth: '80px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {assignedUserName}
                </Typography>
              )}
            </IconButton>
          </Box>
        </CardActions>
      </Card>

      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <DialogTitle>
          {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
        </DialogTitle>
        <DialogContent>
          Are you sure you want to {optimisticShowOnWebsite ? 'hide' : 'show'} this vehicle
          {optimisticShowOnWebsite ? ' from' : ' on'} the website?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmShowHide} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <VehicleForm
        open={openVehicleForm}
        vehicle={selectedVehicle}
        onClose={() => {
          setOpenVehicleForm(false)
        }}
        onSubmit={handleEditVehicle}
        generateStockNumber={() =>
          generateOpticStockNumber(
            (vehicles as Vehicle[])?.map(vehicle => vehicle.stock_number) || [],
            userInfo?.first_name || '',
            userInfo?.last_name || '',
          )
        }
        isEdit={true}
      />
    </>
  )
}

export default VehicleCard
