import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Typography,
  Paper,
  Grid,
  Chip,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
  InputAdornment
} from '@mui/material';
import { Close, Search, Delete, ContentCopy, DeleteForever, DeleteSweep, Warning } from '@mui/icons-material';
import { collection, query, orderBy, limit, startAfter, getDocs, where, doc, deleteDoc, writeBatch, WriteBatch } from 'firebase/firestore';
import { db } from 'core/config/firebase';
import { format } from 'date-fns';
import { getUserProfile } from 'core/api/users/getUserProfile';
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import { useSnackbar } from 'contexts/snackBarContext';
import { RemoveAllConfirmationDialog } from './RemoveAllConfirmationDialog';
import { VehicleDetailsDialog } from './VehicleDetailsDialog';
import { useAuth } from 'contexts/AuthContext';

interface PhotoUploadHistoryProps {
  open: boolean;
  onClose: () => void;
}

interface AccessCode {
  id: string;
  code: string;
  createdAt: string;
  expires: string;
  userId: string;
  userEmail?: string;
  vehicles: Array<{
    id: string;
    vin: string;
  }>;
}

const PAGE_SIZE = 5;

interface RevokeSearchedConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  count: number;
  searchTerm: string;
}

const RevokeSearchedConfirmationDialog: React.FC<RevokeSearchedConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  count,
  searchTerm
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>
      <Box display="flex" alignItems="center" gap={1}>
        <Warning color="error" />
        <Typography variant="h6" color="error">
          Revoke Searched Access Codes
        </Typography>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Typography variant="body1" gutterBottom>
        Are you sure you want to revoke <strong>all {count} access codes</strong> matching your search: "<strong>{searchTerm}</strong>"?
      </Typography>
      <Typography color="error" sx={{ mt: 2, fontWeight: 'bold' }}>
        Warning: This is a destructive action!
      </Typography>
      <Typography color="error.main" sx={{ mt: 1 }}>
        This will immediately invalidate all matching photo upload links. This action cannot be undone.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} variant="outlined">
        Cancel
      </Button>
      <Button 
        onClick={onConfirm} 
        variant="contained" 
        color="error"
        sx={{ fontWeight: 'bold' }}
      >
        Yes, Revoke Matched Access Codes
      </Button>
    </DialogActions>
  </Dialog>
);

export const PhotoUploadHistory: React.FC<PhotoUploadHistoryProps> = ({ open, onClose }) => {
  const [accessCodes, setAccessCodes] = useState<AccessCode[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAccessCodes, setFilteredAccessCodes] = useState<AccessCode[]>([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    open: boolean;
    accessCode: AccessCode | null;
  }>({
    open: false,
    accessCode: null
  });
  const [removeAllConfirmation, setRemoveAllConfirmation] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedVin, setSelectedVin] = useState<string | null>(null);
  const [revokeSearchedConfirmation, setRevokeSearchedConfirmation] = useState(false);
  const { userInfo } = useAuth()
  const { showSnackbar } = useSnackbar();

  const fetchUserEmails = async (docs: AccessCode[]) => {
    const updatedDocs = await Promise.all(
      docs.map(async (doc) => {
        try {
          return {
            ...doc,
            userEmail: userInfo?.email || 'Email not found'
          };
        } catch (error) {
          console.error(`Error fetching user profile for ${doc.userId}:`, error);
          return {
            ...doc,
            userEmail: 'Error fetching email'
          };
        }
      })
    );
    return updatedDocs;
  };

  const fetchAccessCodes = async (isFirstPage = false, searchQuery = '') => {
    setLoading(true);
    try {
      let q;
      
      if (searchQuery) {
        const vinQuery = query(
          collection(db, 'accessCodes'),
          orderBy('createdAt', 'desc'),
        );
        
        const vinSnapshot = await getDocs(vinQuery);
        let matchingDocs = vinSnapshot.docs.filter(doc => {
          const data = doc.data();
          return data.vehicles.some((vehicle: { vin: string }) => 
            vehicle.vin.toLowerCase().includes(searchQuery.toLowerCase())
          );
        });

        const userIds = await getUserIdsByEmail(searchQuery);
        if (userIds.length > 0) {
          const userQuery = query(
            collection(db, 'accessCodes'),
            where('userId', 'in', userIds)
          );
          const userSnapshot = await getDocs(userQuery);
          matchingDocs = [...matchingDocs, ...userSnapshot.docs];
        }

        const uniqueDocs = Array.from(new Set(matchingDocs.map(doc => doc.id)))
          .map(id => matchingDocs.find(doc => doc.id === id)!);

        uniqueDocs.sort((a, b) => b.data().createdAt.localeCompare(a.data().createdAt));

        const docs = uniqueDocs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as AccessCode[];

        const docsWithEmails = await fetchUserEmails(docs);
        setAccessCodes(docsWithEmails);
        setFilteredAccessCodes(docsWithEmails);
        setHasMore(false);
      } else {
        q = query(
          collection(db, 'accessCodes'),
          orderBy('createdAt', 'desc'),
          limit(PAGE_SIZE)
        );

        if (!isFirstPage && lastDoc) {
          q = query(
            collection(db, 'accessCodes'),
            orderBy('createdAt', 'desc'),
            startAfter(lastDoc),
            limit(PAGE_SIZE)
          );
        }

        const snapshot = await getDocs(q);
        const docs = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as AccessCode[];

        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === PAGE_SIZE);

        const docsWithEmails = await fetchUserEmails(docs);

        if (isFirstPage) {
          setAccessCodes(docsWithEmails);
          setFilteredAccessCodes(docsWithEmails);
        } else {
          const newAccessCodes = [...accessCodes, ...docsWithEmails];
          setAccessCodes(newAccessCodes);
          setFilteredAccessCodes(newAccessCodes);
        }
      }
    } catch (error) {
      console.error('Error fetching access codes:', error);
    } finally {
      setLoading(false);
    }
  };

  const getUserIdsByEmail = async (email: string): Promise<string[]> => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '>=', email.toLowerCase()), where('email', '<=', email.toLowerCase() + '\uf8ff'));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => doc.id);
    } catch (error) {
      console.error('Error fetching users by email:', error);
      return [];
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    
    searchTimeout.current = setTimeout(() => {
      fetchAccessCodes(true, term);
    }, 500);
  };

  const searchTimeout = React.useRef<NodeJS.Timeout>();
  useEffect(() => {
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (open) {
      fetchAccessCodes(true);
    }
  }, [open]);

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), 'MMM d, yyyy h:mm a');
  };

  const handleDelete = async (accessCode: AccessCode) => {
    try {
      await deleteDoc(doc(db, 'accessCodes', accessCode.id));
      showSnackbar('Access code revoked successfully', 'success');
      
      setAccessCodes(prev => prev.filter(code => code.id !== accessCode.id));
      setFilteredAccessCodes(prev => prev.filter(code => code.id !== accessCode.id));
    } catch (error) {
      console.error('Error deleting access code:', error);
      showSnackbar('Error revoking access code', 'error');
    } finally {
      setDeleteConfirmation({ open: false, accessCode: null });
    }
  };

  const getAccessLink = (uuid: string) => {
    const baseUrl = process.env.REACT_APP_ENV === 'production' 
      ? 'https://otw-production-91ffb.web.app'
      : 'https://otw-stg.web.app'
    return `${baseUrl}/access/${uuid}`
  };

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link)
      .then(() => {
        showSnackbar('Link copied to clipboard', 'success');
      })
      .catch((error) => {
        console.error('Error copying link:', error);
        showSnackbar('Failed to copy link', 'error');
      });
  };

  const handleRemoveAll = async () => {
    setLoading(true);
    try {
      const snapshot = await getDocs(query(
        collection(db, 'accessCodes'),
        orderBy('createdAt', 'desc')
      ));

      if (snapshot.empty) {
        showSnackbar('No access codes to remove', 'info');
        setRemoveAllConfirmation(false);
        setLoading(false);
        return;
      }

      const batchSize = 500;
      const batches: Array<WriteBatch> = [];
      let currentBatch = writeBatch(db);
      let operationCount = 0;

      snapshot.docs.forEach((doc) => {
        currentBatch.delete(doc.ref);
        operationCount++;

        if (operationCount === batchSize) {
          batches.push(currentBatch);
          currentBatch = writeBatch(db);
          operationCount = 0;
        }
      });

      if (operationCount > 0) {
        batches.push(currentBatch);
      }

      await Promise.all(batches.map(batch => batch.commit()));
      
      showSnackbar(`Successfully removed ${snapshot.size} access codes`, 'success');
      setAccessCodes([]);
      setFilteredAccessCodes([]);
    } catch (error) {
      console.error('Error removing all access codes:', error);
      showSnackbar('Error removing access codes', 'error');
    } finally {
      setLoading(false);
      setRemoveAllConfirmation(false);
    }
  };

  const getTotalCount = async () => {
    try {
      const snapshot = await getDocs(query(
        collection(db, 'accessCodes'),
        orderBy('createdAt', 'desc')
      ));
      return snapshot.size;
    } catch (error) {
      console.error('Error getting total count:', error);
      return 0;
    }
  };

  const handleRemoveAllClick = async () => {
    const count = await getTotalCount();
    setTotalCount(count);
    setRemoveAllConfirmation(true);
  };

  const handleRevokeSearched = async () => {
    setLoading(true);
    try {
      const batch = writeBatch(db);
      
      filteredAccessCodes.forEach((code) => {
        batch.delete(doc(db, 'accessCodes', code.id));
      });

      await batch.commit();
      
      showSnackbar(`Successfully revoked ${filteredAccessCodes.length} matching access codes`, 'success');
      
      setSearchTerm('');
      await fetchAccessCodes(true);

    } catch (error) {
      console.error('Error revoking searched access codes:', error);
      showSnackbar('Error revoking access codes', 'error');
    } finally {
      setLoading(false);
      setRevokeSearchedConfirmation(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '80vh'
        }
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Photo Upload History</Typography>
          <Box display="flex" gap={1} alignItems="center">
            {searchTerm && filteredAccessCodes.length > 0 && (
              <Button
                variant="outlined"
                color="error"
                size="small"
                startIcon={<DeleteSweep />}
                onClick={() => setRevokeSearchedConfirmation(true)}
                sx={{ 
                  mr: 1,
                  opacity: 0.7,
                  '&:hover': {
                    opacity: 1
                  }
                }}
              >
                Revoke Searched
              </Button>
            )}
            {accessCodes.length > 0 && !searchTerm && (
              <Button
                variant="outlined"
                color="error"
                size="small"
                startIcon={<DeleteForever />}
                onClick={handleRemoveAllClick}
                sx={{ 
                  mr: 1,
                  opacity: 0.7,
                  '&:hover': {
                    opacity: 1
                  }
                }}
              >
                Revoke All
              </Button>
            )}
            <IconButton onClick={onClose} size="small">
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <Box sx={{ px: 3, pt: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by VIN or email..."
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
        />
      </Box>

      <DialogContent>
        {filteredAccessCodes.length === 0 && !loading ? (
          <Box sx={{ textAlign: 'center', py: 3 }}>
            <Typography color="textSecondary">
              No results found
            </Typography>
          </Box>
        ) : (
          filteredAccessCodes.map((accessCode) => (
            <Paper
              key={accessCode.id}
              elevation={2}
              sx={{ p: 2, mb: 2, borderRadius: 2 }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Access Code
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {accessCode.code}
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      User Email
                    </Typography>
                    <Typography variant="body2" noWrap>
                      {accessCode.userEmail || 'Loading...'}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Created At
                    </Typography>
                    <Typography variant="body2">
                      {formatDate(accessCode.createdAt)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Expires
                    </Typography>
                    <Typography variant="body2">
                      {formatDate(accessCode.expires)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Access Link
                    </Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          fontFamily: 'monospace',
                          bgcolor: 'grey.100',
                          p: 1,
                          borderRadius: 1,
                          flex: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {getAccessLink(accessCode.id)}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => handleCopyLink(getAccessLink(accessCode.id))}
                        sx={{ 
                          bgcolor: 'primary.main',
                          color: 'white',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          }
                        }}
                      >
                        <ContentCopy fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Vehicles ({accessCode.vehicles.length})
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      {accessCode.vehicles.map((vehicle) => (
                        <Chip
                          key={vehicle.id}
                          label={vehicle.vin}
                          size="small"
                          sx={{ 
                            mr: 1, 
                            mb: 1,
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              bgcolor: 'secondary.main',
                              color: 'secondary.contrastText',
                              transform: 'translateY(-1px)',
                              boxShadow: 1
                            }
                          }}
                          onClick={() => setSelectedVin(vehicle.vin)}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
                <IconButton
                  color="error"
                  onClick={() => setDeleteConfirmation({
                    open: true,
                    accessCode
                  })}
                  sx={{ ml: 1 }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Paper>
          ))
        )}

        {loading && (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress size={24} />
          </Box>
        )}
      </DialogContent>

      <DeleteConfirmationDialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, accessCode: null })}
        onConfirm={() => deleteConfirmation.accessCode && handleDelete(deleteConfirmation.accessCode)}
        id={deleteConfirmation.accessCode?.id || ''}
        code={deleteConfirmation.accessCode?.code || ''}
      />

      <RemoveAllConfirmationDialog
        open={removeAllConfirmation}
        onClose={() => setRemoveAllConfirmation(false)}
        onConfirm={handleRemoveAll}
        count={totalCount}
      />

      <VehicleDetailsDialog
        open={!!selectedVin}
        onClose={() => setSelectedVin(null)}
        vin={selectedVin || ''}
      />

      <RevokeSearchedConfirmationDialog
        open={revokeSearchedConfirmation}
        onClose={() => setRevokeSearchedConfirmation(false)}
        onConfirm={handleRevokeSearched}
        count={filteredAccessCodes.length}
        searchTerm={searchTerm}
      />

      <DialogActions>
        <Button
          onClick={() => fetchAccessCodes()}
          disabled={!hasMore || loading || searchTerm.length > 0}
          variant="outlined"
        >
          Load More
        </Button>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 