import { FirestoreDataConverter, DocumentSnapshot, SnapshotOptions, WithFieldValue } from 'firebase/firestore'

export const vehicleConverter: FirestoreDataConverter<Vehicle> = {
  toFirestore(vehicle: WithFieldValue<Vehicle>) {
    const { id, ...data } = vehicle
    return data
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): Vehicle {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      vin: data?.vin || '',
      who: data?.who || '',
      stock_number: data?.stock_number || '',
      year: data?.year || '',
      make: data?.make || '',
      model: data?.model || '',
      miles: data?.miles || '',
      optic_list_price: data?.optic_list_price || '',
      seller_asking_price: data?.seller_asking_price || '',
      profit: data?.profit || '',
      date_added: data?.date_added || '',
      show_on_website: data?.show_on_website || false,
      size: data?.size || '',
      fuel: data?.fuel || '',
      location: data?.location || '',
      cameras: data?.cameras || '',
      type: data?.type || '',
      sold_date: data?.sold_date || '',
      truck_notes: data?.truck_notes || '',
      condition: data?.condition || '',
      phone_number: data?.phone_number || '',
      email: data?.email || '',
      follow_up_notes: data?.follow_up_notes || '',
      entered_by: data?.entered_by || '',
      payoff_amount: data?.payoff_amount || '',
      status: data?.status || '',
      link: data?.link || '',
      repair_cost: data?.repair_cost || '',
      shipping_cost: data?.shipping_cost || '',
    }
  },
}
