import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@mui/material'
import Editor from '@monaco-editor/react'

interface NotesDialogProps {
  open: boolean
  onClose: () => void
  onSave: (notes: string) => void
  initialValue: string
  title?: string
}

const NotesDialog: React.FC<NotesDialogProps> = ({
  open,
  onClose,
  onSave,
  initialValue,
  title = 'Notes'
}) => {
  React.useEffect(() => {
    setNotes(initialValue);
  }, [initialValue, open]);

  const [notes, setNotes] = React.useState(initialValue)

  const handleSave = () => {
    onSave(notes)
    onClose()
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          height: '80vh',
          maxHeight: '800px',
          bgcolor: '#1e1e1e',
          overflow: 'hidden'
        }
      }}
    >
      <DialogTitle sx={{ 
        bgcolor: '#1e1e1e',
        color: 'white',
        fontFamily: 'monospace',
        p: 1,
        pl: 2
      }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ 
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        <Editor
          height="100%"
          defaultLanguage="markdown"
          theme="vs-dark"
          value={notes}
          onChange={(value) => setNotes(value || '')}
          options={{
            minimap: { enabled: false },
            fontSize: 14,
            wordWrap: 'on',
            lineNumbers: 'on',
            padding: { top: 8 },
            scrollBeyondLastLine: false,
          }}
        />
      </DialogContent>
      <DialogActions sx={{ 
        bgcolor: '#1e1e1e',
        py: 1,
        px: 2,
        borderTop: '1px solid #333'
      }}>
        <Button 
          onClick={onClose} 
          sx={{ color: 'grey.300' }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSave} 
          variant="contained" 
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NotesDialog 