import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from '@mui/material'

interface SaveTemplateDialogProps {
  open: boolean
  onClose: () => void
  onSave: (name: string, description: string) => void
  onUpdate?: (name: string, description: string) => void
  isUpdate?: boolean
  currentTemplate?: {
    name: string
    description?: string
  }
}

export const SaveTemplateDialog: React.FC<SaveTemplateDialogProps> = ({
  open,
  onClose,
  onSave,
  onUpdate,
  isUpdate = false,
  currentTemplate,
}) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (currentTemplate && isUpdate) {
      setName(currentTemplate.name)
      setDescription(currentTemplate.description || '')
    } else {
      setName('')
      setDescription('')
    }
  }, [currentTemplate, isUpdate, open])

  const handleSave = () => {
    if (isUpdate && onUpdate) {
      onUpdate(name, description)
    } else {
      onSave(name, description)
    }
    setName('')
    setDescription('')
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {isUpdate ? 'Update Template Layout' : 'Save Current Layout as Template'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Template Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={2}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={!name} variant="contained">
          {isUpdate ? 'Update Template' : 'Save Template'}
        </Button>
      </DialogActions>
    </Dialog>
  )
} 