import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent, GridApi } from 'ag-grid-community'
import { useTheme } from '@mui/material'
import { useInventory } from 'hooks/useInventory'
import { useAuth } from 'contexts/AuthContext'
import { VehiclePhotosRenderer } from './VehiclePhotosRenderer'
import { getInventoryColumns } from './getInventoryColumns'
import { LoadingInventory } from '../LoadingInventory'
import { Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import { Button, Divider, Typography, Stack, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import ShareIcon from '@mui/icons-material/Share'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import { ActionsPanel } from './ActionsPanel'
import { COLUMN_TEMPLATES, ColumnTemplate, ColumnState } from './columnTemplates'
import { useLocalStorageState } from '@toolpad/core'
import { SaveTemplateDialog } from './SaveTemplateDialog'
import { useManageUsers } from 'hooks/useManageUsers'
import { Column, ColumnPinnedType } from 'ag-grid-community'
import { useSnackbar } from 'contexts/snackBarContext'
import { updateUser } from 'core/api/users'
import { useMutation, useQueryClient } from '@tanstack/react-query';

type GridTheme = {
  name: string
  rowHeight: number
  fontSize: string
}

const defaultColDef: ColDef = {
  sortable: true,
  filter: true,
  resizable: true,
  floatingFilter: true,
  enableValue: true,
  enableRowGroup: true,
  enablePivot: true,
  flex: 1,
  minWidth: 100,
  editable: true,
  singleClickEdit: true,
}

const masterDetailOptions = {
  masterDetail: true,
  detailRowHeight: 600,
  detailRowAutoHeight: true,
  suppressMasterDetailRefit: false,
  isRowMaster: () => true,
}

interface AgGridInventoryProps {
  gridRef: React.RefObject<AgGridReact>
  gridTheme: { name: string; rowHeight: number; fontSize: string }
  rows: Vehicle[]
  isLoading?: boolean
}

// Add these constants at the top of the file, before the component
const ZOOM_LIMITS = {
  FONT: {
    MIN: 8, // Smaller minimum font size
    MAX: 20,
    DEFAULT: 14
  },
  ROW: {
    MIN: 24, // Smaller minimum row height
    MAX: 100,
    DEFAULT: 24
  }
} as const

const statusBar = {
  statusPanels: [
    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
    { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
  ]
}

const AgGridInventory: React.FC<AgGridInventoryProps> = ({ gridRef, gridTheme, rows, isLoading = false }) => {
  const { userInfo, setUserInfo } = useAuth()
  const theme = useTheme()
  const gridApiRef = useRef<GridApi | null>(null)

  const { handleCellValueChange } = useInventory('master_inventory')

  const [selectedRows, setSelectedRows] = useState<Vehicle[]>([])

  const [activeToolPanel, setActiveToolPanel] = useState<string | undefined>(undefined)

  const [expandedRows, setExpandedRows] = useState<string[]>([])

  // Update the initial state to use the default values
  const [fontSize, setFontSize] = useState(`${ZOOM_LIMITS.FONT.DEFAULT}px`)
  const [rowHeight, setRowHeight] = useState<number>(ZOOM_LIMITS.ROW.DEFAULT)

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [activeTemplate, setActiveTemplate] = useLocalStorageState<string>(
    'inventory-column-template',
    'default'
  );

  const [customTemplates, setCustomTemplates] = useLocalStorageState<ColumnTemplate[]>(
    'inventory-custom-templates',
    [] as ColumnTemplate[]
  )
  const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] = useState(false)

  const { columnTemplates, saveTemplate, deleteTemplate, updateTemplate, isLoadingTemplates } = useManageUsers()
  const isAdminUser = userInfo?.user_type === 'admin' || userInfo?.user_type === 'babyAdmin'

  // Add these state declarations after other useState declarations
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const [templateToUpdate, setTemplateToUpdate] = useState<ColumnTemplate | null>(null)

  const { showSnackbar } = useSnackbar()
  useEffect(() => {
    if (isInitialLoad && userInfo) {
      setActiveTemplate(userInfo.default_view || 'default');
      setIsInitialLoad(false);
    }
  }, [userInfo, isInitialLoad]);

  useEffect(() => {
    if (userInfo && columnTemplates) {
      setActiveTemplate(userInfo?.default_view || 'default')
    }
  }, [userInfo, columnTemplates])

  // Move clearAllFilters up here, before it's used in sideBar
  const clearAllFilters = useCallback(() => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null)
    }
  }, [])

  const onSelectionChanged = useCallback(() => {
    const selected = gridApiRef.current?.getSelectedRows() || []
    setSelectedRows(selected)

    if (gridApiRef.current) {
      if (selected.length > 0 && activeToolPanel !== 'actions') {
        gridApiRef.current.openToolPanel('actions')
        setActiveToolPanel('actions')
      } else if (selected.length === 0 && activeToolPanel === 'actions') {
        gridApiRef.current.closeToolPanel()
        setActiveToolPanel(undefined)
      }
    }
  }, [activeToolPanel])

  // Update the sideBar configuration
  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'actions',
          labelDefault: 'Actions',
          labelKey: 'actions',
          iconKey: 'menu',
          toolPanel: () => <ActionsPanel selectedRows={selectedRows} clearAllFilters={clearAllFilters} />,
        },
      ],
      defaultToolPanel: activeToolPanel,
    }),
    [selectedRows, clearAllFilters, activeToolPanel],
  )

  const onCellClicked = useCallback((params: any) => {
    if (params.column.getColId() === 'stock_number') return

    if (params.column?.getColDef().editable !== false) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex!,
        colKey: params.column.getColId(),
      })

      // if (!params.node.isSelected()) {
      //   params.node.setSelected(true)
      // }
    }
  }, [])

  const handleCellValueChanged = useCallback(
    (params: any) => {
      const { data, colDef, newValue } = params
      handleCellValueChange(data.id, colDef.field!, newValue)

      // Only auto-size columns when data changes
      if (gridApiRef.current) {
        const allColumnIds: string[] = []
        gridApiRef.current.getColumns()?.forEach(column => {
          allColumnIds.push(column.getId())
        })
        gridApiRef.current.autoSizeColumns(allColumnIds)
      }
    },
    [handleCellValueChange],
  )

  const isAdmin = userInfo?.user_type === 'admin'
  const columnDefs = useMemo(() => {
    const checkboxColumn: ColDef = {
      headerName: '',
      field: 'checkbox',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      pinned: 'left',
      lockPosition: true,
      suppressMenu: true,
      suppressMovable: true,
      editable: false,
      filter: false,
      floatingFilter: false,
      suppressFiltersToolPanel: true,
    }

    const inventoryColumns = getInventoryColumns(isAdmin)
    const allTemplates = [...COLUMN_TEMPLATES, ...(columnTemplates || [])]
    const template = allTemplates.find(t => t.id === activeTemplate) || COLUMN_TEMPLATES[0]
    
    // Ensure checkbox column is first in the array
    return [checkboxColumn, ...inventoryColumns.map(col => ({
      ...col,
      hide: !template.columns.includes(col.field || '')
    }))]
  }, [isAdmin, activeTemplate, columnTemplates])
  const memoizedVehiclePhotosRenderer = useMemo(() => VehiclePhotosRenderer, [])

  // Add a loading row renderer
  const loadingRenderer = () => {
    return (
      <Box sx={{ height: gridTheme.rowHeight, position: 'relative' }}>
        <LoadingInventory />
      </Box>
    )
  }

  // Update the zoom effect to use the new limits
  useEffect(() => {
    const handleZoom = (e: WheelEvent) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault()
        
        const delta = e.deltaY > 0 ? -1 : 1
        const zoomFactor = 1 + (delta * 0.05)

        setFontSize(prev => {
          const currentSize = parseFloat(prev)
          const newSize = Math.max(
            ZOOM_LIMITS.FONT.MIN,
            Math.min(ZOOM_LIMITS.FONT.MAX, currentSize * zoomFactor)
          )
          return `${newSize}px`
        })

        setRowHeight(prev => {
          return Math.max(
            ZOOM_LIMITS.ROW.MIN,
            Math.min(ZOOM_LIMITS.ROW.MAX, prev * zoomFactor)
          )
        })

        if (gridApiRef.current) {
          gridApiRef.current.resetRowHeights()
        }
      }
    }

    const preventKeyboardZoom = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === '+' || e.key === '-' || e.key === '=')) {
        e.preventDefault()
        
        const delta = (e.key === '-') ? -1 : 1
        const zoomFactor = 1 + (delta * 0.1)

        setFontSize(prev => {
          const currentSize = parseFloat(prev)
          const newSize = Math.max(
            ZOOM_LIMITS.FONT.MIN,
            Math.min(ZOOM_LIMITS.FONT.MAX, currentSize * zoomFactor)
          )
          return `${newSize}px`
        })

        setRowHeight(prev => {
          return Math.max(
            ZOOM_LIMITS.ROW.MIN,
            Math.min(ZOOM_LIMITS.ROW.MAX, prev * zoomFactor)
          )
        })

        if (gridApiRef.current) {
          gridApiRef.current.resetRowHeights()
        }
      }
    }

    document.addEventListener('wheel', handleZoom, { passive: false })
    document.addEventListener('keydown', preventKeyboardZoom)

    return () => {
      document.removeEventListener('wheel', handleZoom)
      document.removeEventListener('keydown', preventKeyboardZoom)
    }
  }, [])

  // Single function to handle column sizing
  const sizeColumnsToFit = useCallback(() => {
    if (!gridApiRef.current) return
    
    // Get all column IDs
    const allColumnIds: string[] = []
    gridApiRef.current.getColumns()?.forEach(column => {
      allColumnIds.push(column.getId())
    })

    // First set reasonable default sizes
    gridApiRef.current.sizeColumnsToFit()
    
    // Then do a single autosize operation
    requestAnimationFrame(() => {
      gridApiRef.current?.autoSizeColumns(allColumnIds, false)
    })
  }, [])

  // Modify onGridReady to only size once
  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api

    // Apply template column state if needed
    const template = [...COLUMN_TEMPLATES, ...(columnTemplates || [])].find(t => t.id === activeTemplate)
    if (template && !COLUMN_TEMPLATES.some(t => t.id === template.id) && template.columnState) {
      params.api.applyColumnState({
        state: template.columnState.map(col => ({
          colId: col.field,
          width: col.width,
          pinned: col.pinned || null,
        })),
        applyOrder: false
      })
    }

    // Size columns only once after grid is ready
    sizeColumnsToFit()
  }, [activeTemplate, columnTemplates, sizeColumnsToFit])

  // Update gridConfig to include zoom styles
  const gridConfig = useMemo(() => ({
    columnDefs: columnDefs,
    rowData: isLoading ? [] : rows,
    defaultColDef,
    ...masterDetailOptions,
    detailCellRenderer: memoizedVehiclePhotosRenderer,
    rowSelection: 'multiple' as const,
    sideBar: sideBar,
    onCellClicked,
    onCellValueChanged: handleCellValueChanged,
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true, // Prevent column recreation
    suppressAnimationFrame: true, // Prevent unnecessary redraws
    colResizeDefault: 'shift' as const,
    domLayout: 'normal' as const,
    rowHeight: rowHeight,  // Add zoom rowHeight
    getRowHeight: () => rowHeight,  // Ensure consistent row height
    rowStyle: { 
      fontSize: fontSize,  // Add zoom fontSize
      lineHeight: `${rowHeight}px`  // Add line height to match row height
    },
    headerHeight: rowHeight,  // Scale header with zoom
    floatingFiltersHeight: rowHeight,  // Scale filters with zoom
    groupDisplayType: 'groupRows' as const,
    animateRows: true,
    rowGroupPanelShow: 'always' as const,
    suppressAggFuncInHeader: true,
    onGridReady,
    onSelectionChanged,
    onToolPanelVisibleChanged: (event: any) => {
      if (event.source === 'toolPanelUi') {
        setActiveToolPanel(event.toolPanel.id)
      }
    },
    statusBar: statusBar,
  }), [
    columnDefs,
    rows,
    isLoading,
    sideBar,
    onCellClicked,
    handleCellValueChanged,
    memoizedVehiclePhotosRenderer,
    fontSize,  // Add fontSize dependency
    rowHeight,  // Add rowHeight dependency
    onGridReady,
    onSelectionChanged,
  ])

  // Add gridKey state near other state declarations
  const [gridKey, setGridKey] = useState(0)

  // Update the effect that handles column state changes
  useEffect(() => {
    if (!gridApiRef.current) return

    const allTemplates = [...COLUMN_TEMPLATES, ...(columnTemplates || [])]
    const template = allTemplates.find(t => t.id === activeTemplate)
    if (!template) return

    // Increment gridKey to force recreation
    setGridKey(prev => prev + 1)

    // Apply column state in a single operation
    gridApiRef.current.setFilterModel(null)
    
    // Ensure checkbox and vehicleIcon are always included
    const mandatoryColumns = ['checkbox', 'vehicleIcon']
    const templateColumns = template.columns || []
    const allColumns = [...new Set([...mandatoryColumns, ...templateColumns])]

    gridApiRef.current.applyColumnState({
      state: columnDefs.map(colDef => ({
        colId: colDef.field || colDef.colId || '',
        // Always show checkbox column and preserve its functionality
        hide: colDef.field === 'checkbox' || colDef.colId === 'checkbox' 
          ? false 
          : !template.columns.includes(colDef.field || colDef.colId || ''),
        // Preserve checkbox selection functionality
        headerCheckboxSelection: colDef.field === 'checkbox' || colDef.colId === 'checkbox',
        checkboxSelection: colDef.field === 'checkbox' || colDef.colId === 'checkbox'
      })),
      defaultState: { hide: true },
      applyOrder: true
    })

    // Size columns only after state is applied
    requestAnimationFrame(sizeColumnsToFit)
  }, [activeTemplate, columnTemplates, sizeColumnsToFit])

  const handleSaveCurrentTemplate = async (name: string, description: string) => {
    if (!gridApiRef.current || !isAdminUser) return

    // Get all columns in their current order
    const allColumns = gridApiRef.current.getAllGridColumns()
    
    // Get visible columns but ensure mandatory columns are included
    const mandatoryColumns = ['checkbox', 'vehicleIcon']
    const visibleColumns = allColumns
      .filter((col: Column) => col.isVisible())
      .map((col: Column) => col.getColId())
    
    // Combine mandatory columns with visible columns, removing duplicates
    const columns = [...new Set([...mandatoryColumns, ...visibleColumns])]
    
    // Create column state for all included columns
    const columnState = allColumns
      .filter((col: Column) => columns.includes(col.getColId()))
      .map((col: Column) => ({
        field: col.getColId(),
        width: col.getActualWidth() || undefined,
        pinned: col.getPinned() || undefined,
      } as ColumnState))

    // Create the template
    const template: Omit<ColumnTemplate, 'id' | 'createdBy' | 'createdAt'> = {
      name,
      columns,
      description: description || undefined,
      columnState: columnState.length > 0 ? columnState : undefined,
    }

    await saveTemplate(template)
    setSaveTemplateDialogOpen(false)
  }

  const handleUpdateTemplate = async (name: string, description: string) => {
    if (!gridApiRef.current || !isAdminUser || !templateToUpdate) return

    const allColumns = gridApiRef.current.getAllGridColumns()
    
    const columnState = allColumns
      .filter((col: Column) => col.isVisible())
      .map((col: Column) => ({
        field: col.getColId(),
        width: col.getActualWidth() || undefined,
        pinned: col.getPinned() || undefined,
      } as ColumnState))
      .filter(state => state.field !== undefined)

    const updatedTemplate: Partial<ColumnTemplate> = {
      name,
      description: description || undefined,
      columns: columnState.map(col => col.field).filter(Boolean),
      columnState: columnState.length > 0 ? columnState : undefined,
    }

    try {
      await updateTemplate({ 
        templateId: templateToUpdate.id!, 
        template: updatedTemplate 
      })
      setActiveTemplate(templateToUpdate.id!)
      setIsUpdateMode(false)
      setTemplateToUpdate(null)
    } catch (error) {
      console.error('Error updating template:', error)
      showSnackbar('Error updating template', 'error')
    }
  }

  const queryClient = useQueryClient();
  
  // Add this state to track the optimistic default view
  const [optimisticDefaultView, setOptimisticDefaultView] = useState<string | undefined>(
    userInfo?.default_view
  );

  // Update the mutation
  const updateDefaultViewMutation = useMutation({
    mutationFn: async (templateId: string) => {
      await updateUser(userInfo?.firestoreDocId!, {
        default_view: templateId
      });
      return templateId;
    },
    onMutate: async (newTemplateId) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ 
        queryKey: ['user', userInfo?.firestoreDocId] 
      });

      // Snapshot the previous value
      const previousUser = queryClient.getQueryData(['user', userInfo?.firestoreDocId]);
      const previousDefaultView = optimisticDefaultView;

      // Optimistically update the UI
      setOptimisticDefaultView(newTemplateId);

      // Return a context object with the snapshotted value
      return { previousUser, previousDefaultView };
    },
    onError: (err, newTemplateId, context) => {
      // If the mutation fails, roll back
      setOptimisticDefaultView(context?.previousDefaultView);
      queryClient.setQueryData(
        ['user', userInfo?.firestoreDocId],
        context?.previousUser
      );
      showSnackbar('Error updating default view', 'error');
    },
    onSuccess: (templateId) => {
      queryClient.invalidateQueries({ 
        queryKey: ['user', userInfo?.firestoreDocId] 
      });
      queryClient.invalidateQueries({ 
        queryKey: ['users'] 
      });
      showSnackbar('Default view updated', 'success');
    }
  });

  const TemplateSelector = () => {
    const allTemplates = [
      ...COLUMN_TEMPLATES,
      ...(columnTemplates || [])
    ]
    
    const currentTemplate = allTemplates.find(t => t.id === activeTemplate)
    const isCustomTemplate = currentTemplate && !COLUMN_TEMPLATES.some(t => t.id === currentTemplate.id)

    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
        <FormControl size="small" sx={{ minWidth: 350 }}>
          <InputLabel>Column Template</InputLabel>
          <Select
            value={activeTemplate}
            label="Column Template"
            onChange={(e) => setActiveTemplate(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 450 }
              }
            }}
          >
            <MenuItem value="" disabled>
              <Typography sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                Preset Templates
              </Typography>
            </MenuItem>
            
            {COLUMN_TEMPLATES.map(template => (
              <MenuItem 
                key={template.id} 
                value={template.id}
                sx={{ 
                  py: 1.5,
                  borderLeft: theme => 
                    optimisticDefaultView === template.id
                      ? `3px solid ${theme.palette.warning.main}` 
                      : '3px solid transparent'
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  width: '100%',
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    width: '100%',
                  }}>
                    <Typography sx={{ fontWeight: 500 }}>
                      {template.name}
                      {optimisticDefaultView === template.id && (
                        <Typography 
                          component="span" 
                          sx={{ 
                            ml: 1,
                            color: 'warning.main',
                            fontSize: '0.75rem',
                            fontStyle: 'italic'
                          }}
                        >
                          (Default)
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                  {template.description && (
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ mt: 0.5 }}
                    >
                      {template.description}
                    </Typography>
                  )}
                </Box>
              </MenuItem>
            ))}

            {columnTemplates && columnTemplates.length > 0 && (
              <MenuItem value="" disabled sx={{ mt: 1 }}>
                <Typography sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                  Custom Templates
                </Typography>
              </MenuItem>
            )}

            {columnTemplates?.map(template => (
              <MenuItem 
                key={template.id} 
                value={template.id}
                sx={{ 
                  py: 1.5,
                  borderLeft: theme => 
                    optimisticDefaultView === template.id
                      ? `3px solid ${theme.palette.warning.main}` 
                      : '3px solid transparent'
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  width: '100%',
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    width: '100%',
                  }}>
                    <Typography sx={{ fontWeight: 500 }}>
                      {template.name}
                      {optimisticDefaultView === template.id && (
                        <Typography 
                          component="span" 
                          sx={{ 
                            ml: 1,
                            color: 'warning.main',
                            fontSize: '0.75rem',
                            fontStyle: 'italic'
                          }}
                        >
                          (Default)
                        </Typography>
                      )}
                    </Typography>
                    {isAdminUser && (
                      <Box sx={{ display: 'flex', gap: 0.5 }}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setTemplateToUpdate(template);
                            setIsUpdateMode(true);
                            setSaveTemplateDialogOpen(true);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteTemplate(template.id!);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                  {template.description && (
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ mt: 0.5 }}
                    >
                      {template.description}
                    </Typography>
                  )}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Set Default button - taller height */}
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            if (!activeTemplate) return;
            updateDefaultViewMutation.mutate(activeTemplate);
          }}
          startIcon={optimisticDefaultView === activeTemplate ? 
            <StarIcon sx={{ color: 'warning.main' }} /> : 
            <StarBorderIcon />
          }
          sx={{ 
            alignSelf: 'center',
            minWidth: 200,
            height: '40px'  // Match MUI default button height
          }}
        >
          {optimisticDefaultView === activeTemplate ? 'Default View' : 'Set as Default'}
        </Button>

        {/* Save As and Update buttons - taller height */}
        {isAdminUser && (
          <>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setIsUpdateMode(false);
                setSaveTemplateDialogOpen(true);
              }}
              sx={{ 
                alignSelf: 'center',
                minWidth: 200,
                height: '40px'  // Match MUI default button height
              }}
            >
              Save As New Template
            </Button>

            {/* Check if current template is custom before showing Update button */}
            {activeTemplate && !COLUMN_TEMPLATES.some(t => t.id === activeTemplate) && (
              <Button
                variant="contained"
                size="small"
                onClick={handleUpdateCurrentTemplate}
              >
                Update Current Template
              </Button>
            )}
          </>
        )}
      </Box>
    );
  };

  // Update the handleUpdateCurrentTemplate function
  const handleUpdateCurrentTemplate = async () => {
    if (!gridApiRef.current || !isAdminUser) return

    const allTemplates = [...COLUMN_TEMPLATES, ...(columnTemplates || [])]
    const currentTemplate = allTemplates.find(t => t.id === activeTemplate)
    
    if (!currentTemplate || COLUMN_TEMPLATES.some(t => t.id === currentTemplate.id)) {
      showSnackbar('Cannot update preset template', 'error')
      return
    }

    // Get current column state
    const allColumns = gridApiRef.current.getAllGridColumns()
    const columnState = allColumns
      .filter((col: Column) => col.isVisible())
      .map((col: Column) => ({
        field: col.getColId(),
        width: col.getActualWidth() || undefined,
        pinned: col.getPinned() || undefined,
      } as ColumnState))
      .filter(state => state.field !== undefined)

    const updatedTemplate: Partial<ColumnTemplate> = {
      name: currentTemplate.name,
      description: currentTemplate.description || undefined,
      columns: columnState.map(col => col.field).filter(Boolean),
      columnState: columnState.length > 0 ? columnState : undefined,
    }

    try {
      await updateTemplate({ 
        templateId: currentTemplate.id!, 
        template: updatedTemplate 
      })
      showSnackbar('Template updated successfully', 'success')
    } catch (error) {
      console.error('Error updating template:', error)
      showSnackbar('Error updating template', 'error')
    }
  }

  // Update the isInitialLoad check to include template loading
  const showLoading = isInitialLoad || isLoadingTemplates;

  return (
    <Box>
      {showLoading ? (
        <LoadingInventory />
      ) : (
        <>
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            alignItems: 'center', 
            mb: 2,
            justifyContent: 'space-between'  // Separate into left and right groups
          }}>
            {/* Left group - Template selector and default view */}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <FormControl size="small" sx={{ minWidth: 350 }}>
                <InputLabel>Column Template</InputLabel>
                <Select
                  value={activeTemplate}
                  label="Column Template"
                  onChange={(e) => setActiveTemplate(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      sx: { maxHeight: 450 }
                    }
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                      Preset Templates
                    </Typography>
                  </MenuItem>
                  
                  {COLUMN_TEMPLATES.map(template => (
                    <MenuItem 
                      key={template.id} 
                      value={template.id}
                      sx={{ 
                        py: 1.5,
                        borderLeft: theme => 
                          optimisticDefaultView === template.id
                            ? `3px solid ${theme.palette.warning.main}` 
                            : '3px solid transparent'
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          alignItems: 'center',
                          width: '100%',
                        }}>
                          <Typography sx={{ fontWeight: 500 }}>
                            {template.name}
                            {optimisticDefaultView === template.id && (
                              <Typography 
                                component="span" 
                                sx={{ 
                                  ml: 1,
                                  color: 'warning.main',
                                  fontSize: '0.75rem',
                                  fontStyle: 'italic'
                                }}
                              >
                                (Default)
                              </Typography>
                            )}
                          </Typography>
                        </Box>
                        {template.description && (
                          <Typography 
                            variant="caption" 
                            color="text.secondary"
                            sx={{ mt: 0.5 }}
                          >
                            {template.description}
                          </Typography>
                        )}
                      </Box>
                    </MenuItem>
                  ))}

                  {columnTemplates && columnTemplates.length > 0 && (
                    <MenuItem value="" disabled sx={{ mt: 1 }}>
                      <Typography sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                        Custom Templates
                      </Typography>
                    </MenuItem>
                  )}

                  {columnTemplates?.map(template => (
                    <MenuItem 
                      key={template.id} 
                      value={template.id}
                      sx={{ 
                        py: 1.5,
                        borderLeft: theme => 
                          optimisticDefaultView === template.id
                            ? `3px solid ${theme.palette.warning.main}` 
                            : '3px solid transparent'
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          alignItems: 'center',
                          width: '100%',
                        }}>
                          <Typography sx={{ fontWeight: 500 }}>
                            {template.name}
                            {optimisticDefaultView === template.id && (
                              <Typography 
                                component="span" 
                                sx={{ 
                                  ml: 1,
                                  color: 'warning.main',
                                  fontSize: '0.75rem',
                                  fontStyle: 'italic'
                                }}
                              >
                                (Default)
                              </Typography>
                            )}
                          </Typography>
                          {isAdminUser && (
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setTemplateToUpdate(template);
                                  setIsUpdateMode(true);
                                  setSaveTemplateDialogOpen(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteTemplate(template.id!);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                        {template.description && (
                          <Typography 
                            variant="caption" 
                            color="text.secondary"
                            sx={{ mt: 0.5 }}
                          >
                            {template.description}
                          </Typography>
                        )}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  if (!activeTemplate) return;
                  updateDefaultViewMutation.mutate(activeTemplate);
                }}
                startIcon={optimisticDefaultView === activeTemplate ? 
                  <StarIcon sx={{ color: 'warning.main' }} /> : 
                  <StarBorderIcon />
                }
              >
                {optimisticDefaultView === activeTemplate ? 'Default View' : 'Set as Default'}
              </Button>
            </Box>

            {/* Right group - Template management buttons */}
            {isAdminUser && (
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setIsUpdateMode(false);
                    setSaveTemplateDialogOpen(true);
                  }}
                >
                  Save As New Template
                </Button>

                {/* Check if current template is custom before showing Update button */}
                {activeTemplate && !COLUMN_TEMPLATES.some(t => t.id === activeTemplate) && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleUpdateCurrentTemplate}
                  >
                    Update Current Template
                  </Button>
                )}
              </Box>
            )}
          </Box>
          <div
            key={gridKey}
            className={theme.palette.mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'}
            style={{ width: '100%', height: '80vh' }}
          >
            <AgGridReact ref={gridRef} {...gridConfig} />
          </div>
          <SaveTemplateDialog
            open={saveTemplateDialogOpen}
            onClose={() => {
              setSaveTemplateDialogOpen(false)
              setIsUpdateMode(false)
              setTemplateToUpdate(null)
            }}
            onSave={handleSaveCurrentTemplate}
            onUpdate={handleUpdateTemplate}
            isUpdate={isUpdateMode}
            currentTemplate={templateToUpdate || undefined}
          />
        </>
      )}
    </Box>
  )
}

export default React.memo(AgGridInventory)

