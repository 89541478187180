import { ColumnPinnedType } from 'ag-grid-community'

export interface ColumnState {
  field: string;
  width?: number | undefined;
  pinned?: ColumnPinnedType | undefined;
}

export type ColumnTemplate = {
  id?: string;
  name: string;
  columns: string[];
  description?: string;
  columnState?: ColumnState[];
  createdBy?: string;
  createdAt?: string;
  isDefault?: boolean;
}

export const COLUMN_TEMPLATES: ColumnTemplate[] = [
  {
    id: 'default',
    name: 'Default View',
    description: 'Shows all columns in standard order',
    columns: [
      'checkbox',
      'vehicleIcon',
      'stock_number',
      'who',
      'year',
      'make',
      'model',
      'size',
      'miles',
      'optic_list_price',
      'seller_asking_price',
      'profit',
      'vin',
      'status',
      'type',
      'link',
      'photos',
      'condition',
      'date_added',
      'fuel',
      'location',
      'sold_date',
      'truck_notes',
      'phone_number',
      'email',
      'follow_up_notes',
      'entered_by',
      'payoff_amount',
      'repair_cost',
      'shipping_cost',
      'show_on_website',
    ],
  },
  {
    id: 'financial',
    name: 'Financial View',
    description: 'Focus on prices and profit',
    columns: [
      '',
      'checkbox',
      'vehicleIcon',
      'stock_number',
      'optic_list_price',
      'seller_asking_price',
      'profit',
      'repair_cost',
      'shipping_cost',
      'payoff_amount',
      'sold_date',
      'status',
    ],
  },
  {
    id: 'vehicle-details',
    name: 'Vehicle Details',
    description: 'Focus on vehicle specifications',
    columns: [
      '',
      'checkbox',
      'vehicleIcon',
      'year',
      'make',
      'model',
      'size',
      'miles',
      'fuel',
      'vin',
      'type',
    ],
  },
] 